import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import Link from 'next/link';
import { State } from 'reducer/reducer';
import { getToken, isTokenValid } from 'lib/authentication';
import { logout, openLoginModal } from 'reducer/actions';
import Image from 'components/Image';
import styled from 'styled-components';

type CoverImageProps = {
    $isAvailable: boolean;
};

const CoverImage = styled(Image)<CoverImageProps>`
    filter: ${({ $isAvailable }) => ($isAvailable ? 'none' : 'grayscale(0.6)')};
`;

export const Cover = ({
    issue
}: {
    issue: Pick<Issue, 'eIssueUrl' | 'eIssueUrlProtected' | 'name' | 'cover'>;
}) => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state: State) => state.isLoggedIn);
    const [token, setToken] = useState<string>();
    const isAvailable = useCallback(() => {
        return issue.eIssueUrlProtected ? isTokenValid() : true;
    }, [token, issue.eIssueUrlProtected]);

    useEffect(() => {
        setToken(getToken());
    }, [isLoggedIn]);

    if (issue.eIssueUrl) {
        const handleOpenEIssueClick = (event: React.MouseEvent<HTMLElement>) => {
            if (!isAvailable()) {
                event.preventDefault();
                dispatch(logout());
                dispatch(openLoginModal());
                return;
            }
        };

        return (
            <a
                target="_blank"
                href={issue.eIssueUrl}
                rel="nofollow noreferrer"
                onClick={handleOpenEIssueClick}
            >
                <CoverImage
                    $isAvailable={isAvailable()}
                    type="issue"
                    width={271}
                    height={388}
                    alt={issue.name || 'Aktualny numer'}
                    src={issue.cover}
                />
            </a>
        );
    }

    return (
        <Link href="/aktualny-numer">
            <Image type="issue" width={271} height={388} alt={issue.name} src={issue.cover} />
        </Link>
    );
};

export default Cover;
