import { SectionTitle } from 'components/Section';
import PatronateSection from './PatronateSection';
import UpcomingEventsSection from './UpcomingEventsSection';

type Props = {
    eventsPatronate: Pick<Occurrence, 'name' | 'logo' | 'seoSlug'>[];
    events: Occurrence[];
};

export const EventsSection = ({ eventsPatronate, events }: Props) => {
    return (
        <>
            <SectionTitle title="Wydarzenia" url="/wydarzenia" />
            {eventsPatronate && <PatronateSection events={eventsPatronate} />}
            {events && <UpcomingEventsSection events={events} />}
        </>
    );
};
