import { Col, Row } from 'antd';
import { SubTitle } from 'components/Section';
import PatronateItem from './PatronateItem';

type Props = {
    events: Pick<Occurrence, 'name' | 'logo' | 'seoSlug'>[];
};

export const PatronateSection = ({ events }: Props) => {
    return (
        <Row>
            <Col style={{ marginBottom: 17 }}>
                <SubTitle category="Nasz patronat" />
            </Col>
            <Col span={24}>
                <Row justify="center" gutter={[32, 16]}>
                    {events.map(({ seoSlug, name, logo }) => (
                        <PatronateItem
                            key={`${seoSlug}-patronate`}
                            event={{ seoSlug, name, logo }}
                        />
                    ))}
                </Row>
            </Col>
        </Row>
    );
};

export default PatronateSection;
