import React from 'react';
import getConfig from 'next/config';
import { GetServerSideProps } from 'next';
import BasicLayout from 'layout/Basic';
import HomePage, { Props } from 'containers/HomePage';
import { convertProps } from 'lib/parseAPIResponse';

export const index = (props: Props): JSX.Element => {
    return (
        <BasicLayout ads={props.ads}>
            <HomePage {...props} />
        </BasicLayout>
    );
};

export const getServerSideProps: GetServerSideProps = async ({ res }) => {
    res.setHeader('Cache-Control', 'public, s-maxage=59, stale-while-revalidate=800');
    const { apiUrl } = getConfig().publicRuntimeConfig;
    return Promise.all([
        fetch(`${apiUrl}/public/api/homepage`),
        fetch(`${apiUrl}/public/api/naszadsik?path=home`)
    ]).then(async ([res1, res2]) => {
        const data = await res1.json();
        const ads = await res2.json();
        return { props: { ...convertProps(ads), ...convertProps(data) } };
    });
};

export default index;
