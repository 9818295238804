import { Row } from 'antd';
import styled from 'styled-components';

export const OpeningRow = styled(Row)`
    background: ${(props) => props.theme.colors.primaryBackground};
    width: 100%;
    aspect-ratio: 7/4;
    @media (min-width: 624px) and (max-width: 996px) {
        /* aspect-ratio: 7/4; */
        aspect-ratio: 6/5;
    }
    @media (max-width: 624px) {
        /* aspect-ratio: 7/4; */
        aspect-ratio: 4/5;
    }
`;

export default OpeningRow;
