import { Col } from 'antd';
import Section, { Heading } from 'components/Section';
import Tags from 'components/Tags';
import getConfig from 'next/config';

type Props = { tags: Tag[] };

export const TagsSection = ({ tags }: Props) => {
    const { shortName } = getConfig().publicRuntimeConfig;
    return (
        <Section gutter={[16, 16]}>
            <Col span={24}>
                <Heading level={1} style={{ textTransform: 'none' }}>
                    #{shortName}
                </Heading>
            </Col>
            <Col span={24}>
                <Tags tags={tags} sameSize={false} />
            </Col>
        </Section>
    );
};
