import React from 'react';
import OpeningRow from './OpeningRow';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import resolveImagePath from 'lib/resolveImagePath';

declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        fetchpriority?: 'high' | 'low' | 'auto';
    }
}
const DesktopSection = dynamic(() => import('./DesktopSection'), {
    ssr: true
});
const MobileSection = dynamic(() => import('./MobileSection'), {
    ssr: true
});

interface Props {
    $items: {
        articles: Article[];
        contests: Contest[];
    };
}

const OpeningSection = ({ $items }: Props): JSX.Element => {
    const screens = useBreakpoint();
    const isSmallScreen = screens.lg === false;
    const isLargeScreen = screens.lg === true;

    const { articles, contests } = $items;

    const items = [
        ...contests.map((contest) => ({
            title: contest.name,
            category: contest.category,
            openingImage: contest.openingImage,
            openingImageInfo: contest.openingImageInfo,
            seoSlug: contest.seoSlug,
            type: 'contest' as HitboxItemType
        })),
        ...articles.map((article) => ({
            title: article.title,
            category: article.category,
            openingImage: article.openingImage,
            openingImageInfo: article.openingImageInfo,
            seoSlug: article.seoSlug,
            type: 'article' as HitboxItemType
        }))
    ];
    const getMainItems = (): HitboxItem[] => {
        return items
            .slice(0, items.length - 2)
            .sort(() => 0.5 - Math.random())
            .slice(0, 3);
    };

    const getSideItems = () => {
        return items.slice(-2);
    };
    const mainItems = getMainItems();
    const sideItems = getSideItems();
    return (
        <>
            <Head>
                <link
                    key={mainItems[0].title}
                    rel="preload"
                    fetchPriority="high"
                    as="image"
                    href={resolveImagePath(mainItems[0].openingImage, 'index_big')}
                    type="image/webp"
                />
                {sideItems
                    .filter((item) => item.openingImage)
                    .map((item) => (
                        <link
                            key={item.title}
                            rel="preload"
                            fetchPriority="high"
                            as="image"
                            href={resolveImagePath(item.openingImage, 'index')}
                            type="image/webp"
                        />
                    ))}
            </Head>
            <OpeningRow>
                {isLargeScreen && <DesktopSection mainItems={mainItems} sideItems={sideItems} />}
                {isSmallScreen && <MobileSection mainItems={mainItems} sideItems={sideItems} />}
            </OpeningRow>
        </>
    );
};

OpeningSection.whyDidYouRender = true;

export default OpeningSection;
