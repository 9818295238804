import Link from 'next/link';
import styled from 'styled-components';

const Item = styled(Link)`
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 26px;
    letter-spacing: normal;
    text-align: left;
    color: ${(props) => props.color || props.theme.colors.greyishBrownTwo};
`;

export default Item;
