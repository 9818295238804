import React from 'react';
import { Col, Row } from 'antd';
import ArticleCard from 'components/ArticleCard';
import { SectionTitle } from 'components/Section';

const ROWS = 24;

export interface Item extends Article {
    first?: boolean;
}

interface Props {
    title: string | JSX.Element;
    url?: string;
    items: Item[];
    itemSize?: number;
}

const FirstArticlePromotedSection = ({ title, url, items, itemSize = 8 }: Props): JSX.Element => {
    return (
        <Row>
            <SectionTitle title={title} url={url} />
            <Col span={24}>
                <Row gutter={[16, 30]}>
                    <ArticleCard
                        key={items[0].seoSlug}
                        $article={items[0]}
                        size={{ span: 24, md: ROWS - itemSize }}
                        large
                        noTeaser
                    />
                    {items.slice(1, 5).map((item) => (
                        <ArticleCard
                            key={item.seoSlug}
                            $article={item}
                            size={{ span: 24, md: itemSize }}
                        />
                    ))}
                </Row>
            </Col>
        </Row>
    );
};

export default FirstArticlePromotedSection;
