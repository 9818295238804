import { Col, Tooltip } from 'antd';
import Link from 'next/link';
import Image from 'components/Image';

type Props = {
    event: Pick<Occurrence, 'name' | 'seoSlug' | 'logo'>;
};

export const PatronateItem = ({ event: { name, seoSlug, logo } }: Props) => {
    return (
        <Col key={`${seoSlug}-patronate`}>
            <Link href={`/wydarzenia/${seoSlug}`}>
                <Tooltip title={name}>
                    <Image type="logo" height={96} width={96} alt={name} src={logo} />
                </Tooltip>
            </Link>
        </Col>
    );
};

export default PatronateItem;
