import React from 'react';
import { TagCloud } from 'react-tagcloud';
import Link from 'next/link';
import styled from 'styled-components';

interface Props {
    tags: Tag[];
    sameSize?: boolean;
}

interface StyledProps {
    fontColor: string;
    fontSize: number;
    $sameSize: boolean;
}

const Tag = styled.span<StyledProps>`
    margin: 3px;
    padding: 0 15px;
    border-radius: 22px;
    vertical-align: middle;
    color: ${({ theme }) => theme.colors.greyishBrown};
    font-size: ${({ fontSize, $sameSize }) => ($sameSize ? '14px' : `${fontSize}px`)};
    font-weight: 300;
    letter-spacing: normal;
    font-stretch: normal;

    border: 1px solid #b7cbd9;
    display: inline-block;
`;

const StyledClodud = styled(TagCloud)`
    text-align: center;
`;

const Tags = ({ tags, sameSize = true }: Props): JSX.Element => {
    const customRenderer = (tag, size, color) => (
        <Link key={tag.value} href={`/tagi/${tag.props.slug}`}>
            <Tag fontSize={size || 14} $sameSize={sameSize} fontColor={color}>
                {tag.value}
            </Tag>
        </Link>
    );

    return (
        <StyledClodud
            minSize={10}
            maxSize={28}
            disableRandomColor
            shuffle={true}
            tags={tags.map((tag) => ({
                value: tag.name,
                count: tag.popularity,
                props: {
                    slug: tag.seoSlug
                }
            }))}
            renderer={customRenderer}
        />
    );
};

export default Tags;
