import { Col, Row } from 'antd';
import CardGrid from 'components/CardGrid';
import { SubTitle } from 'components/Section';
import Link from 'next/link';

type Props = {
    events: Occurrence[];
};

export const UpcomingEventsSection = ({ events }: Props) => {
    return (
        <Row>
            <Col>
                <SubTitle
                    category={
                        <Link href="/wydarzenia">
                            Najbliższe wydarzenia{' '}
                            <small style={{ paddingLeft: 10, fontSize: 14 }}>wiecej</small>
                        </Link>
                    }
                />
            </Col>
            <Col span={24}>
                <CardGrid type="event" noImages items={events} maxItems={4} maxItemsInRow={4} />
            </Col>
        </Row>
    );
};

export default UpcomingEventsSection;
