import React from 'react';
import { Row, Col, Divider } from 'antd';
import { SectionTitle } from 'components/Section';
import Link from 'next/link';
import Section from 'components/Section';
import IssueMenuItem from './Item';
import Cover from './Cover';

interface Props {
    issue: Issue;
    showAboutHref?: boolean;
    showSubscriptionHref?: boolean;
    showAdHref?: boolean;
    showContactHref?: boolean;
    showArchiveHref?: boolean;
}

const OurIssueSection = ({
    issue,
    showAboutHref = true,
    showSubscriptionHref = true,
    showAdHref = true,
    showContactHref = true,
    showArchiveHref = false
}: Props): JSX.Element => {
    return (
        <Col span={24}>
            <SectionTitle title="Nasz magazyn" url="/o-tytule" />
            <Row>
                <Col span={24}>
                    <Divider>
                        <Link href="/aktualny-numer">Aktualne wydanie</Link>
                    </Divider>
                </Col>
            </Row>
            <Row justify="center">
                <Col>
                    <Cover issue={issue} />
                </Col>
            </Row>
            <Row justify="center">
                <Col>
                    <IssueMenuItem href="/aktualny-numer#table-of-content">
                        Spis treści
                    </IssueMenuItem>
                </Col>
            </Row>
            <Section justify="center">
                <Col>
                    <Divider>Czasopismo</Divider>
                </Col>
            </Section>
            {showAboutHref && (
                <Row justify="center">
                    <Col>
                        <IssueMenuItem href="/o-tytule">O tytule</IssueMenuItem>
                    </Col>
                </Row>
            )}
            {showSubscriptionHref && (
                <Row justify="center">
                    <Col>
                        <IssueMenuItem href="/prenumerata">Prenumerata</IssueMenuItem>
                    </Col>
                </Row>
            )}
            {showArchiveHref && (
                <Row justify="center">
                    <Col>
                        <IssueMenuItem href="/archiwum">Archiwum</IssueMenuItem>
                    </Col>
                </Row>
            )}
            {showAdHref && (
                <Row justify="center">
                    <Col>
                        <IssueMenuItem href="/reklama">Reklama</IssueMenuItem>
                    </Col>
                </Row>
            )}
            {showContactHref && (
                <Row justify="center">
                    <Col>
                        <IssueMenuItem href="/kontakt">Kontakt</IssueMenuItem>
                    </Col>
                </Row>
            )}
        </Col>
    );
};

export default OurIssueSection;
